import { Link, Outlet, useLoaderData, useLocation, useNavigation, useRouteLoaderData } from 'react-router-dom';
import { JobEvent, User } from '../types';
import { useEffect, useState, useRef } from 'react';
import { BrowserProvider, ethers } from 'ethers';

import { formatEther } from 'ethers';
import { Button, buttonVariants } from './ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { AlertCircle } from 'lucide-react';
import Toast from './Toast';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet';
import { LinearLoader } from './ui/linear-loader';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const Layout: React.FC = () => {
  const location = useLocation();
  const { state } = useNavigation();
  const me = useRouteLoaderData('me') as User;
  const weiBalance: BigInt = BigInt(me.weiBalance);
  const minWeiBalance: BigInt = BigInt(10 ** 15);

  const [provider, setProvider] = useState<ethers.BrowserProvider | undefined>(undefined);
  const [network, setNetwork] = useState<ethers.Network | undefined>(undefined);

  const isLoading = state === 'loading';

  // Initialize provider
  useEffect(() => {
    const initializeProvider = async () => {
      if (window.ethereum) {
        try {
          // Request user accounts
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          // Create a new provider instance
          const providerInstance = new ethers.BrowserProvider(window.ethereum, 'any');
          const network = await providerInstance.getNetwork();
          setProvider(providerInstance);
          setNetwork(network);
        } catch (error) {
          console.error('Error connecting to wallet:', error);
        }
      } else {
        console.error('Ethereum provider not found');
      }
    };

    initializeProvider();
  }, []);

  // Track network changes
  useEffect(() => {
    if (provider) {
      const handleChainChanged = (chainId: string) => {
        console.log('Chain changed:', chainId);
        window.location.reload();
      };

      // Subscribe to chainChanged event directly from window.ethereum
      window.ethereum.on('chainChanged', handleChainChanged);

      // Cleanup listener when component unmounts
      return () => {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, [provider]);

  // track account changes
  useEffect(() => {
    if (provider) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log('Accounts changed:', accounts);
        localStorage.removeItem('token');
        window.location.href = '/';
      };

      // Subscribe to accountsChanged event directly from window.ethereum
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      // Cleanup listener when component unmounts
      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
  }, [provider]);

  return (
    <div className='flex min-h-screen w-full flex-col'>
      {isLoading && <LinearLoader />}
      <header className='flex items-center justify-between p-1 sm:p-2 bg-black text-white'>
        <Link className='text-2xl font-semibold' to={`/`}>
          Cipherdolls
        </Link>

        <div className='hidden sm:block'>
          <div className='flex items-center gap-4'>
            <p>{formatEther(me.weiBalance).substring(0, 8)}... Ether</p>
            <Link to={`/users/${me.id}`}>My Account</Link>
          </div>
        </div>

        <Sheet>
          <SheetTrigger className='block sm:hidden px-3'>Navi</SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetDescription>
                <nav className='grid gap-4 text-2xl text-muted-foreground' x-chunk='dashboard-04-chunk-0'>
                  <Link to='/avatars' className={classNames(location.pathname.includes('/avatars') ? 'font-semibold text-primary' : '')}>
                    Avatars
                  </Link>

                  <Link to='/chats' className={classNames(location.pathname.includes('/chats') ? 'font-semibold text-primary' : '')}>
                    Chats
                  </Link>

                  <Link to='/dolls' className={classNames(location.pathname.includes('/dolls') ? 'font-semibold text-primary' : '')}>
                    Dolls
                  </Link>

                  <Link
                    to={`/users/${me.id}`}
                    className={classNames(location.pathname.includes('/users') ? 'font-semibold text-primary' : '')}
                  >
                    My Account
                  </Link>

                  <p>{formatEther(me.weiBalance).substring(0, 8)}... Ether</p>
                </nav>
              </SheetDescription>
            </SheetHeader>
          </SheetContent>
        </Sheet>
      </header>

      <main className='flex flex-1 flex-col gap-4 bg-muted/40 p-1 md:gap-8 md:p-10'>
        <div className='mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]'>
          <div className='hidden sm:block'>
            <nav className='grid gap-4 text-sm text-muted-foreground' x-chunk='dashboard-04-chunk-0'>
              <Link to='/avatars' className={classNames(location.pathname.includes('/avatars') ? 'font-semibold text-primary' : '')}>
                Avatars
              </Link>

              <Link to='/chats' className={classNames(location.pathname.includes('/chats') ? 'font-semibold text-primary' : '')}>
                Chats
              </Link>

              <Link to='/dolls' className={classNames(location.pathname.includes('/dolls') ? 'font-semibold text-primary' : '')}>
                Dolls
              </Link>
            </nav>
          </div>

          <div className='grid gap-6'>
            {network?.name !== 'optimism' ? (
              <Alert variant='destructive'>
                <AlertCircle className='h-4 w-4' />
                <AlertTitle>Wrong Network</AlertTitle>
                <AlertDescription>Your are on {network?.name}. please switsh to Optimistic in Metamask</AlertDescription>
              </Alert>
            ) : null}

            {weiBalance < minWeiBalance ? (
              <Alert variant='destructive'>
                <AlertCircle className='h-4 w-4' />
                <AlertTitle>Low Balance</AlertTitle>
                <AlertDescription>
                  Your have {formatEther(me.weiBalance).substring(0, 6)} Ether in your Cipherdolls Wallet. But you need at least{' '}
                  {formatEther(10 ** 15)} Ether. please send some Ether to your Cipherdolls wallet.
                </AlertDescription>
              </Alert>
            ) : null}

            <Outlet />
          </div>
        </div>
      </main>
      <Toast />
    </div>
  );
};

export default Layout;
