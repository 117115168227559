import { ActionFunction, Form, redirect, useLoaderData } from 'react-router-dom';
import { Scenario } from '../types';
import axios from 'axios';
import { backendUrl } from '..';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  if (!params.scenarioId) return;
  try {
    await axios.delete(`${backendUrl}/scenarios/${params.scenarioId}`, config);
    return redirect(`/scenarios`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ScenarioDelete() {
  const scenario = useLoaderData() as Scenario;
  return (
    <Form method='post' action='destroy'>
      <Button type='submit' variant='destructive'>
        Delete
      </Button>
    </Form>
  );
}
