import { ActionFunction, Form, Link, redirect, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { useState } from 'react';
import { Scenario } from '@/types';
import DynamicSelectInput from '@/components/DynamicSelectInput';

export const action: ActionFunction = async ({ request }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    return redirect('/signin');
  }

  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);
  try {
    const response = await axios.post(`${backendUrl}/scenarios`, body, config);
    const scenario: Scenario = response.data;
    return redirect(`/scenarios/${scenario.id}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ScenarioNew() {
  const [checked, setChecked] = useState(false);
  let { avatarId } = useParams();

  return (
    <Card>
      <CardHeader>
        <CardTitle>New Scenario</CardTitle>
      </CardHeader>
      <Form method='post' id='scenario-form' encType='multipart/form-data'>
        <input type='text' name='avatarId' value={avatarId} readOnly hidden />
        <CardContent>
          <div className='grid gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Movie Night' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='shortDesc'>Short Description</Label>
              <Input
                id='shortDesc'
                name='shortDesc'
                type='text'
                className='w-full'
                placeholder='{{user}} and {{char}} watch a Movie together'
                required
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='greeting'>Greeting</Label>
              <Textarea
                id='greeting'
                name='greeting'
                rows={2}
                className='w-full'
                placeholder='oh {{user}} I am so looking forward to this Movie'
                required
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Description</Label>
              <Textarea required id='description' name='description' rows={20} placeholder='description in w++ format' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Picture</Label>
              <Input id='file-button' name='picture' type='file' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>published</Label>
              <Checkbox id='published' name='published' checked={checked} onCheckedChange={() => setChecked(!checked)} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='chatModelId'>Chat Model</Label>
              <DynamicSelectInput selectID='chatModelId' apiUrl='/chat-models' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='embeddingModelId'>Embedding Model</Label>
              <DynamicSelectInput selectID='embeddingModelId' apiUrl='/embedding-models' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='Temperature'>Temperature</Label>
              <Input id='temperature' name='temperature' type='number' defaultValue='0.2' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='TopP'>TopP</Label>
              <Input id='topP' name='topP' type='number' defaultValue='1' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='frequencyPenalty'>Frequency Penalty</Label>
              <Input id='frequencyPenalty' name='frequencyPenalty' type='number' defaultValue='0.2' required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='presencePenalty'>Presence Penalty</Label>
              <Input id='presencePenalty' name='presencePenalty' type='number' defaultValue='0.2' required />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/avatars/${avatarId}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
