import { ActionFunction, Form, redirect, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from '..';

export const action: ActionFunction = async ({ params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  if (!params.dollId) return;
  try {
    await axios.delete(`${backendUrl}/dolls/${params.dollId}`, config);
    return redirect(`/dolls`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function DollDelete() {
  return (
    <Form method='post' action='destroy'>
      <button
        type='submit'
        className='h-12 border-black border-2 p-2.5  bg-[#FFA6F6]  hover:bg-[#fa8cef]  hover:shadow-[2px_2px_0px_rgba(0,0,0,1)] active:bg-[#f774ea]'
      >
        Delete
      </button>
    </Form>
  );
}
