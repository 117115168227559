import React from 'react';
import { Form, useSubmit, useActionData } from 'react-router-dom';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Terminal } from 'lucide-react';

interface MessageTextNewProps {
  chatId: string;
}

const MessageTextNew: React.FC<MessageTextNewProps> = ({ chatId }) => {
  const submit = useSubmit();
  const actionData = useActionData() as { error?: string }; // Get the error from the action

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    submit(e.currentTarget.form);
    e.currentTarget.form!.reset();
  };

  return (
    <Form method='post'>
      <input name='chatId' defaultValue={chatId} hidden />
      <input
        required
        name='content'
        type='text'
        className='w-96 border-black border-2 p-2.5 focus:outline-none'
        placeholder='Type your message...'
      />
      <button onClick={handleSubmit} className='h-12 p-2.5 bg-[#A6FAFF] hover:bg-[#79F7FF]'>
        Send
      </button>

      {actionData?.error && (
        <Alert variant='destructive'>
          <Terminal className='h-4 w-4' />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{actionData.error}</AlertDescription>
        </Alert>
      )}
    </Form>
  );
};

export default MessageTextNew;
