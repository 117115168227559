import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Avatar, Chat, User } from '../types';
import { backendUrl } from '..';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';

const Chats = () => {
  const chats = useLoaderData() as Chat[];
  return (
    <Card>
      <CardHeader>
        <CardTitle>Chats</CardTitle>
        <CardDescription>Showing all your Chats.</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Avatar</TableHead>
              <TableHead>Scenario</TableHead>
              <TableHead className='hidden sm:table-cell'>Messages</TableHead>
              <TableHead className='text-right'>Action</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {chats.map((chat) => (
              <TableRow>
                <TableCell>{chat.scenario.avatar.name}</TableCell>

                <TableCell>{chat.scenario.name}</TableCell>
                <TableCell className='hidden sm:table-cell'>{chat._count.messages}</TableCell>
                <TableCell className='text-right'>
                  <Button size='sm' variant='outline'>
                    <Link to={`/chats/${chat.id}/messages`}>Messages</Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className='text-xs text-muted-foreground'>To Start a new Chat go to avatars</div>
      </CardFooter>
    </Card>
  );
};

export default Chats;
