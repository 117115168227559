import React from 'react';
import axios from 'axios';
import { Chat } from '../types';

import { ActionFunction, Link, Outlet, redirect, useLoaderData, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { backendUrl } from '..';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.chatId) return;
  await axios.patch(backendUrl + `/chats/${params.chatId}`, updates, config);
  return redirect(`/chats/${params.chatId}`);
};

const ChatMessages: React.FC = () => {
  return <Outlet />;
};

export default ChatMessages;
