import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import { Scenario } from '../types';
import axios from 'axios';
import { backendUrl } from '..';
import { Button } from '@/components/ui/button';
import { CardHeader, CardTitle, CardContent, CardFooter, Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import { useState } from 'react';
import ScenarioDelete from './ScenarioDelete';
import DynamicSelectInput from '@/components/DynamicSelectInput';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    return redirect('/signin');
  }

  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.scenarioId) return;
  try {
    await axios.patch(`${backendUrl}/scenarios/${params.scenarioId}`, updates, config);
    return redirect(`/scenarios/${params.scenarioId}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ScenarioEdit() {
  const scenario = useLoaderData() as Scenario;
  const {
    name,
    shortDesc,
    greeting,
    published,
    description,
    avatarId,
    chatModel,
    embeddingModel,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
  } = scenario;

  const [checked, setChecked] = useState(published);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit Scenario</CardTitle>
      </CardHeader>
      <Form method='post' id='scenario-form' encType='multipart/form-data'>
        <input type='text' name='avatarId' value={avatarId} readOnly hidden />
        <CardContent>
          <div className='grid gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Alice' defaultValue={name} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='shortDesc'>Short Description</Label>
              <Input
                id='shortDesc'
                name='shortDesc'
                type='text'
                className='w-full'
                placeholder='Alice the helpful assistant'
                defaultValue={shortDesc}
                required
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='greeting'>Greeting</Label>
              <Textarea id='greeting' name='greeting' rows={2} className='w-full' placeholder='Alice' defaultValue={greeting} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Description</Label>
              <Textarea
                required
                defaultValue={description}
                id='description'
                name='description'
                rows={20}
                placeholder='description in w++ format'
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Picture</Label>
              <Input id='file-button' name='picture' type='file' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>published</Label>
              <Checkbox id='published' name='published' checked={checked} onCheckedChange={() => setChecked(!checked)} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='chatModelId'>Chat Model</Label>
              <DynamicSelectInput selectID='chatModelId' apiUrl='/chat-models' defaultSelectID={chatModel.id} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='embeddingModelId'>Embedding Model</Label>
              <DynamicSelectInput selectID='embeddingModelId' apiUrl='/embedding-models' defaultSelectID={embeddingModel.id} />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='Temperature'>Temperature</Label>
              <Input id='temperature' name='temperature' type='string' defaultValue={temperature} required />
            </div>
            <div className='grid gap-3'>
              <Label htmlFor='TopP'>TopP</Label>
              <Input id='topP' name='topP' type='string' defaultValue={topP} required />
            </div>
            <div className='grid gap-3'>
              <Label htmlFor='frequencyPenalty'>Frequency Penalty</Label>
              <Input id='frequencyPenalty' name='frequencyPenalty' type='string' defaultValue={frequencyPenalty} required />
            </div>
            <div className='grid gap-3'>
              <Label htmlFor='presencePenalty'>Presence Penalty</Label>
              <Input id='presencePenalty' name='presencePenalty' type='string' defaultValue={presencePenalty} required />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/scenarios/${scenario.id}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
