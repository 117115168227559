import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './components/ui/card';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { User } from './types';

const Home: React.FC = () => {
  const me = useRouteLoaderData('me') as User;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Quick Start</CardTitle>
      </CardHeader>

      <CardContent>
        {' '}
        To Start a new Chat go to{' '}
        <Link to={`/avatars`} className=' underline'>
          Avatars
        </Link>
        <Separator className='my-4' />
        to Change your name from {me.name} to something else go to{' '}
        <Link to={`/users/${me.id}`} className=' underline'>
          Your Account
        </Link>
      </CardContent>
    </Card>
  );
};

export default Home;
