import { ActionFunction, Form, redirect, useLoaderData } from 'react-router-dom';
import { Chat } from '../types';
import axios from 'axios';
import { backendUrl } from '..';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  if (!params.chatId) return;
  try {
    await axios.delete(`${backendUrl}/chats/${params.chatId}`, config);
    return redirect(`/chats`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function ChatDelete() {
  const chat = useLoaderData() as Chat;
  return (
    <Form method='post' action='destroy'>
      <Button type='submit' variant='destructive'>
        Delete
      </Button>
    </Form>
  );
}
