import { Form, Link, useActionData, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Avatar, SttProvider, User } from '../types';
import { backendUrl } from '..';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { PlusCircle, Terminal } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

type AvatarsLoaderData = {
  avatars: Avatar[];
  sttProviders: SttProvider[];
};

const Avatars = () => {
  const me = useRouteLoaderData('me') as User;
  const data = useLoaderData() as AvatarsLoaderData;
  const { avatars, sttProviders } = data;
  const recommendedSttProviderId = sttProviders[0].id;
  const actionData = useActionData() as { error?: string }; // Get the error from the action

  return (
    <>
      {actionData?.error && (
        <Alert variant='destructive'>
          <Terminal className='h-4 w-4' />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{actionData.error}</AlertDescription>
        </Alert>
      )}
      <div className='ml-auto'>
        <Button size='sm' className='h-8 gap-1'>
          <PlusCircle className='h-3.5 w-3.5' />
          <Link to='/avatars/new'>Add Avatar</Link>
        </Button>
      </div>

      {avatars.map((avatar) => (
        <Card x-chunk='dashboard-06-chunk-0'>
          <CardHeader className='flex flex-row items-start bg-muted/50 gap-3'>
            <div className='grid gap-0.5'>
              <img
                src={
                  avatar.picture
                    ? `${backendUrl}/avatars/${avatar.id}/picture.webp?x=65&y=64`
                    : `https://placehold.co/64x64?text=No+Picture`
                }
                alt=''
              />
            </div>
            <div className='grid gap-0.5'>
              <CardTitle className='group flex items-center gap-2 text-lg'>{avatar.name}</CardTitle>
              <CardDescription>{avatar.shortDesc}</CardDescription>
            </div>
            <div className='ml-auto flex items-center gap-1'>
              <Button size='sm' variant='outline'>
                <Link to={`/avatars/${avatar.id}`}>View</Link>
              </Button>
            </div>
          </CardHeader>

          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>shortDesc</TableHead>
                  <TableHead className='hidden sm:table-cell'>Visibility</TableHead>
                  <TableHead className='text-right'>Action</TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {avatar.scenarios.map((scenario) => (
                  <TableRow>
                    <TableCell>{scenario.name}</TableCell>
                    <TableCell>{scenario.shortDesc}</TableCell>
                    <TableCell className='hidden sm:table-cell'>
                      {scenario.published ? <Badge variant='outline'>Public</Badge> : <Badge variant='outline'>Private</Badge>}
                    </TableCell>

                    <TableCell className='text-right'>
                      <Form method='post' id='chat-form'>
                        <input hidden name='scenarioId' id='scenarioId' value={scenario.id} readOnly />
                        <input hidden name='sttProviderId' id='sttProviderId' value={recommendedSttProviderId} readOnly />
                        <Button type='submit' size='sm'>
                          Chat
                        </Button>
                      </Form>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
          <CardFooter>
            <div className='text-xs text-muted-foreground'>List of scenarios that use this avatar</div>
          </CardFooter>
        </Card>
      ))}
    </>
  );
};

export default Avatars;
