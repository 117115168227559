import React, { useState } from 'react';
import { Chat, Message } from '../types';
import { backendUrl } from '..';
import { Link } from 'react-router-dom';

interface MessageItemProps {
  chat: Chat;
  message: Message;
}

const MessageItem: React.FC<MessageItemProps> = ({ chat, message }) => {
  return (
    <div className='chat-message' key={message.id}>
      <div className={`flex items-end ${message.role === 'USER' ? 'justify-end' : ''}`}>
        <div
          className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${message.role === 'USER' ? 'order-1 items-end' : 'order-2 items-start'}`}
        >
          <Link to={`/messages/${message.id}`}>
            <div>
              <span
                className={`px-4 py-2 rounded-lg inline-block  ${
                  message.role === 'USER'
                    ? 'rounded-br-none bg-white border-black border-2 text-black text-sm'
                    : 'rounded-bl-none bg-gray-800 text-white text-sm'
                }`}
              >
                {message.content}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
