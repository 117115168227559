import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = () => {
  const location = useLocation();
  const localStorageToken = localStorage.getItem('token');

  return localStorageToken ? <Outlet /> : <Navigate to='/signin' replace state={{ from: location }} />;
};

export default ProtectedRoutes;
