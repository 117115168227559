import { ActionFunction, Form, Link, redirect, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Avatar, Scenario, User } from '../types';
import ScenarioDelete from './ScenarioDelete';
import { backendUrl } from '..';
import { Button } from '@/components/ui/button';
import { CardHeader, CardTitle, CardContent, CardDescription, CardFooter, Card } from '@/components/ui/card';
import { Separator } from '@radix-ui/react-dropdown-menu';

export default function ScenarioView() {
  const me = useRouteLoaderData('me') as User;
  const scenario = useLoaderData() as Scenario;
  const avatar: Avatar = scenario.avatar;

  return (
    <main className='grid flex-1 items-start gap-4 sm:p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <Card>
          <CardHeader>
            <CardTitle>Greeting</CardTitle>
          </CardHeader>

          <CardContent>
            <div className='whitespace-pre-line'>{scenario.greeting}</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Setting</CardTitle>
          </CardHeader>

          <CardContent>
            <div className='whitespace-pre-line'>{scenario.description}</div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{scenario.name}</CardTitle>
          <CardDescription>{scenario.shortDesc}</CardDescription>
        </CardHeader>

        <CardContent>
          <img
            className='object-cover'
            src={
              scenario.picture
                ? `${backendUrl}/scenarios/${scenario.id}/picture.webp?x=300&y=300`
                : `https://placehold.co/300x300?text=No+Picture`
            }
            alt=''
          />
          <Separator className='my-4' />

          <div className='font-semibold'>ChatModel</div>
          <span className='text-muted-foreground'>{scenario.chatModel.name}</span>
          <Separator className='my-4' />

          <div className='font-semibold'>Model Parameters</div>
          <ul className='grid gap-3'>
            <li className='flex items-center justify-between'>
              <span className='text-muted-foreground'>Temperature</span>
              <span>{scenario.temperature}</span>
            </li>
            <li className='flex items-center justify-between'>
              <span className='text-muted-foreground'>TopP</span>
              <span>{scenario.topP}</span>
            </li>

            <li className='flex items-center justify-between'>
              <span className='text-muted-foreground'>Frequency Penalty</span>
              <span>{scenario.frequencyPenalty}</span>
            </li>

            <li className='flex items-center justify-between'>
              <span className='text-muted-foreground'>Presence Penalty</span>
              <span>{scenario.presencePenalty}</span>
            </li>
          </ul>
        </CardContent>

        <CardFooter className='flex justify-between'>
          {me.id === avatar.userId && (
            <>
              <Button className='gap-1'>
                <Link to={`/scenarios/${scenario.id}/edit`}>Edit</Link>
              </Button>
              <ScenarioDelete />
            </>
          )}
        </CardFooter>
      </Card>
    </main>
  );
}
