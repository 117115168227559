import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';

import { backendUrl } from '..';
import { Message, User } from '../types';
import MessageDelete from './MessageDelete';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

const MessageDetails = () => {
  const message = useLoaderData() as Message;
  const sttJob = message.sttJob;
  const ttsJob = message.ttsJob;

  const me = useRouteLoaderData('me') as User;

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Message</CardTitle>
          <CardDescription>{message.role}</CardDescription>
        </CardHeader>

        <CardContent>
          <div className='whitespace-pre-line'>{message.content}</div>
          <Separator className='my-4' />

          {message.ttsJob && (
            <>
              <p className='text-xl'>Text to Speech</p>
              <audio controls src={`${backendUrl}/messages/${message.id}/audio`} />

              <div>timeTakenMs: {ttsJob.timeTakenMs}</div>
              <div>usdCost: {ttsJob.usdCost}</div>

              {ttsJob.paymentJob && (
                <>
                  <Separator className='my-4' />
                  <p className='text-xl'>Paymenty</p>
                  <div className='ml-1 inline-block w-[250px] md:w-[500px] xl:w-[800px]'>
                    <a
                      href={`https://optimistic.etherscan.io/tx/${ttsJob.paymentJob.txHash}`}
                      className='underline line-clamp-1'
                      target='_blank'
                      rel={ttsJob.paymentJob.txHash}
                    >
                      txHash:{ttsJob.paymentJob.txHash}
                    </a>
                  </div>
                </>
              )}
            </>
          )}

          {message.sttJob && (
            <>
              <p className='text-xl'>Speech to Text</p>

              <div>timeTakenMs: {sttJob.timeTakenMs}</div>
              <div>usdCost: {sttJob.usdCost}</div>

              <audio controls src={`${backendUrl}/messages/${message.id}/audio`} />
              {sttJob.paymentJob && (
                <>
                  <Separator className='my-4' />

                  <div className='ml-1 inline-block w-[250px] md:w-[500px] xl:w-[800px]'>
                    <a
                      href={`https://optimistic.etherscan.io/tx/${sttJob.paymentJob.txHash}`}
                      className='underline line-clamp-1'
                      target='_blank'
                      rel={sttJob.paymentJob.txHash}
                    >
                      txHash:{sttJob.paymentJob.txHash}
                    </a>
                  </div>
                </>
              )}
            </>
          )}
        </CardContent>
        <CardFooter className='flex justify-between'>
          <Button className='gap-1'>
            <Link to={`/chats/${message.chatId}/messages`}>Cancel</Link>
          </Button>
          <MessageDelete />
        </CardFooter>
      </Card>

      {/* {message.sttJob && (
        <Card>
          <CardHeader>
            <CardTitle>Speech To Text</CardTitle>
          </CardHeader>

          <CardContent>
            <div>timeTakenMs: {sttJob.timeTakenMs}</div>
            <div>usdCost: {sttJob.usdCost}</div>

            <audio controls src={`${backendUrl}/messages/${message.id}/audio`} />
            {sttJob.paymentJob && (
              <>
                <Separator className='my-4' />

                <div className='ml-1 inline-block w-[250px] md:w-[500px] xl:w-[800px]'>
                  <a
                    href={`https://optimistic.etherscan.io/tx/${sttJob.paymentJob.txHash}`}
                    className='underline line-clamp-1'
                    target='_blank'
                    rel={sttJob.paymentJob.txHash}
                  >
                    txHash:{sttJob.paymentJob.txHash}
                  </a>
                </div>
              </>
            )}
          </CardContent>
        </Card>
      )} */}
    </>
  );
};

export default MessageDetails;
