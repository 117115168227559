import React, { useState, useEffect } from 'react';
import { Form, useSubmit, useActionData, useRouteLoaderData, useFetcher } from 'react-router-dom';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Terminal } from 'lucide-react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import { backendUrl } from '..';

import { JobEvent, Message, TtsJob, User } from '@/types';
import AudioPlayer from './AudioPlayer';

interface MessageAudioNewProps {
  chatId: string;
  onRecordingStart: () => void;
}

const MessageAudioNew: React.FC<MessageAudioNewProps> = ({ chatId, onRecordingStart }) => {
  const me = useRouteLoaderData('me') as User;
  const submit = useSubmit(); // Use react-router-dom submit function

  const actionData = useActionData() as { error?: string };

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = useReactMediaRecorder({
    audio: true,
    onStop: (blobUrl, blob) => {
      const file = new File([blob], 'audio.webm', { type: 'audio/webm' });
      const formData = new FormData();
      formData.append('file', file);
      formData.append('chatId', chatId);

      // Automatically submit the form when the recording is ready
      submit(formData, { method: 'post', encType: 'multipart/form-data' });

      // Clear the recording URL after submission
      clearBlobUrl();
    },
  });

  const handleStartRecording = () => {
    onRecordingStart();
    startRecording();
  };

  return (
    <>
      {status !== 'recording' ? (
        <button onClick={handleStartRecording} className='px-4 py-2 bg-green-500 text-white rounded-lg'>
          Start Recording
        </button>
      ) : (
        <button onClick={stopRecording} className='px-4 py-2 bg-red-500 text-white rounded-lg'>
          Stop Recording
        </button>
      )}

      <Form method='post' id='message-form' encType='multipart/form-data'>
        <input name='chatId' defaultValue={chatId} hidden />
        <input name='file' type='file' id='fileInput' hidden />

        {actionData?.error && (
          <Alert variant='destructive'>
            <Terminal className='h-4 w-4' />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{actionData.error}</AlertDescription>
          </Alert>
        )}
      </Form>
    </>
  );
};

export default MessageAudioNew;
