import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Outlet, RouterProvider, createBrowserRouter, defer, redirect } from 'react-router-dom';
import SignIn from './components/SignIn';
import axios, { AxiosError } from 'axios';

import ProtectedRoutes from './components/ProtectedRoutes';

import Avatars from './avatar/Avatars';
import AvatarView from './avatar/AvatarView';
import AvatarNew, { action as avatarNewAction } from './avatar/AvatarNew';
import AvatarEdit, { action as avatarEditAction } from './avatar/AvatarEdit';
import { action as avatarDeleteAction } from './avatar/AvatarDelete';

import Scenarios from './scenario/Scenarios';
import ScenarioView from './scenario/ScenarioView';
import ScenarioNew, { action as scenarioNewAction } from './scenario/ScenarioNew';
import ScenarioEdit, { action as scenarioEditAction } from './scenario/ScenarioEdit';
import { action as scenarioDeleteAction } from './scenario/ScenarioDelete';

import Messages from './message/Messages';
import ChatView from './chat/ChatView';

import MessageNew, { action as massageNewAction } from './message/MessageNew';
import { action as messageDeleteAction } from './message/MessageDelete';

import ChatNew, { action as chatNewAction } from './chat/ChatNew';
import ChatMessages from './chat/ChatMessages';
import { action as chatDeleteAction } from './chat/ChatDelete';

import Dolls from './doll/Dolls';
import DollView from './doll/DollView';
import DollEdit, { action as dollEditAction } from './doll/DollEdit';
import { action as dollDeleteAction } from './doll/DollDelete';

import Layout from './components/Layout';
import Home from './Home';
import Chats from './chat/Chats';
import MessageDetails from './message/MessageDetails';

import UserView from './user/UserView';
import UserEdit, { action as userEditAction } from './user/UserEdit';
import NotFound from './404';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';
export const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

const getData = async (path: string) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    return redirect('/signin');
  }

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };
  try {
    const response = await axios.get(backendUrl + path, headers);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        return redirect('/signin');
      }
    }
  }
};

const router = createBrowserRouter([
  {
    element: <ProtectedRoutes />,
    loader: async () => getData(`/users/me`),
    id: 'me',
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: '/',
            element: <Home />,
          },

          {
            path: '/users/:userId',
            element: <UserView />,
            loader: async ({ params }) => getData(`/users/${params.userId}`),
          },
          {
            path: '/users/:userId/edit',
            element: <UserEdit />,
            loader: async ({ params }) => getData(`/users/${params.userId}`),
            action: userEditAction,
          },

          {
            path: '/users/:userId/refresh',
            action: userEditAction,
          },

          {
            path: '/ai-providers',
            loader: async () => getData(`/ai-providers`),
          },
          {
            path: '/chat-models',
            loader: async () => getData(`/chat-models`),
          },
          {
            path: '/chat-model-parameters',
            loader: async () => getData(`/chat-model-parameters`),
          },
          {
            path: '/embedding-models',
            loader: async () => getData(`/embedding-models`),
          },

          {
            path: '/tts-jobs/:ttsJobId',
            loader: async ({ params }) => getData(`/tts-jobs/${params.ttsJobId}`),
          },

          {
            path: '/tts-providers',
            loader: async () => getData(`/tts-providers`),
          },
          {
            path: '/tts-voices',
            loader: async () => getData(`/tts-voices`),
          },
          {
            path: '/stt-providers',
            loader: async () => getData(`/stt-providers`),
          },

          {
            path: '/chat-completion-jobs/:chatCompletionJobId',
            loader: async ({ params }) => getData(`/chat-completion-jobs/${params.chatCompletionJobId}`),
          },

          {
            path: '/avatars',
            element: <Avatars />,
            loader: async () => {
              const [avatars, sttProviders] = await Promise.all([getData(`/avatars`), getData(`/stt-providers`)]);
              return defer({ avatars, sttProviders });
            },
            action: chatNewAction,
          },
          {
            path: '/avatars/:avatarId',
            element: <AvatarView />,
            loader: async ({ params }) => getData(`/avatars/${params.avatarId}`),
          },
          {
            path: '/avatars/new',
            element: <AvatarNew />,
            action: avatarNewAction,
          },
          {
            path: '/avatars/:avatarId/edit',
            element: <AvatarEdit />,
            loader: async ({ params }) => getData(`/avatars/${params.avatarId}`),
            action: avatarEditAction,
          },
          {
            path: '/avatars/:avatarId/destroy',
            action: avatarDeleteAction,
          },

          // {
          //   path: '/scenarios',
          //   element: <Scenarios />,
          //   loader: async () => getData(`/scenarios`),
          // },
          {
            path: '/scenarios/:scenarioId',
            element: <ScenarioView />,
            loader: async ({ params }) => getData(`/scenarios/${params.scenarioId}`),
          },

          // {
          //   path: '/scenarios/:scenarioId/chats/new',
          //   loader: async ({ params }) => getData(`/scenarios/${params.scenarioId}`),
          //   element: <ChatNew />,
          //   action: chatNewAction,
          // },

          {
            path: '/avatars/:avatarId/scenarios/new',
            element: <ScenarioNew />,
            action: scenarioNewAction,
          },
          {
            path: '/scenarios/:scenarioId/edit',
            element: <ScenarioEdit />,
            loader: async ({ params }) => getData(`/scenarios/${params.scenarioId}`),
            action: scenarioEditAction,
          },
          {
            path: '/scenarios/:scenarioId/destroy',
            action: scenarioDeleteAction,
          },
          {
            path: '/dolls',
            element: <Dolls />,
            loader: async () => getData(`/dolls`),
          },
          {
            path: '/dolls/:dollId',
            element: <DollView />,
            loader: async ({ params }) => getData(`/dolls/${params.dollId}`),
          },
          {
            path: '/dolls/:dollId/edit',
            element: <DollEdit />,
            loader: async ({ params }) => getData(`/dolls/${params.dollId}`),
            action: dollEditAction,
          },
          {
            path: '/dolls/:dollId/destroy',
            action: dollDeleteAction,
          },
          {
            path: '/messages/:messageId',
            element: <MessageDetails />,
            loader: async ({ params }) => getData(`/messages/${params.messageId}`),
          },
          {
            path: '/messages/:messageId/destroy',
            action: messageDeleteAction,
          },
          {
            path: '/chats',
            element: <Chats />,
            loader: async () => getData(`/chats`),
          },
          {
            path: '/chats/:chatId',
            element: <ChatView />,
            loader: async ({ params }) => getData(`/chats/${params.chatId}`),
          },
          {
            element: <ChatMessages />,
            loader: async ({ params }) => getData(`/chats/${params.chatId}`),
            id: 'chat',
            children: [
              {
                path: '/chats/:chatId/messages',
                element: <Messages />,
                loader: async ({ params }) => getData(`/messages/?chatId=${params.chatId}`),
                children: [
                  {
                    path: '/chats/:chatId/messages',
                    element: <MessageNew />,
                    action: massageNewAction,
                  },
                ],
              },
            ],
          },

          {
            path: '/chats/:chatId/destroy',
            action: chatDeleteAction,
          },
        ],
      },
    ],
  },
  {
    path: '/signin',
    element: <SignIn />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
