import { ActionFunction, Form, Link, redirect, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Avatar, Scenario, User } from '../types';
import axios from 'axios';
import AvatarDelete from './AvatarDelete';
import { backendUrl } from '..';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ClipboardPen, PlusCircle } from 'lucide-react';
import { Separator } from '@radix-ui/react-dropdown-menu';

export default function AvatarView() {
  const avatar = useLoaderData() as Avatar;
  const me = useRouteLoaderData('me') as User;
  const [count, setCount] = useState(0);

  return (
    <main className='grid flex-1 items-start gap-4 sm:p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <Card>
          <CardHeader>
            <CardTitle>Character Card</CardTitle>
          </CardHeader>
          <CardContent>
            <div className='whitespace-pre-line'>{avatar.character}</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Scenarios</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-[100px]'>Name</TableHead>
                  <TableHead>ShortDesc</TableHead>
                  <TableHead className='text-right'>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {avatar.scenarios.map((scenario) => (
                  <TableRow>
                    <TableCell className='font-medium'>{scenario.name}</TableCell>
                    <TableCell>{scenario.shortDesc}</TableCell>

                    <TableCell className='text-right'>
                      <Button size='sm' variant='outline'>
                        <Link to={`/scenarios/${scenario.id}`}>View</Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
          {me.id === avatar.userId && (
            <CardFooter className='justify-center border-t p-4'>
              <Button size='sm' variant='ghost' className='gap-1'>
                <PlusCircle className='h-3.5 w-3.5' />
                <Link to={`/avatars/${avatar.id}/scenarios/new`}>New Scenario</Link>
              </Button>
            </CardFooter>
          )}
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{avatar.name}</CardTitle>
          <CardDescription>{avatar.shortDesc}</CardDescription>
        </CardHeader>

        <CardContent>
          <img
            className='object-cover'
            src={
              avatar.picture
                ? `${backendUrl}/avatars/${avatar.id}/picture.webp?x=300&y=300`
                : `https://placehold.co/300x300?text=No+Picture`
            }
            alt=''
          />

          <Separator className='my-4' />
          <div className='font-semibold'>Creater</div>
          <span className='text-muted-foreground'>{avatar.userId}</span>
        </CardContent>

        <CardFooter className='flex justify-between'>
          {me.id === avatar.userId && (
            <>
              <Button className='gap-1'>
                <Link to={`/avatars/${avatar.id}/edit`}>Edit</Link>
              </Button>
              <AvatarDelete />
            </>
          )}
        </CardFooter>
      </Card>
    </main>
  );
}
