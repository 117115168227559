import { Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Doll, User } from '../types';
import { KeyIcon, PlusIcon } from '@heroicons/react/24/outline';
import { backendUrl } from '..';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';

const Dolls = () => {
  const dolls = useLoaderData() as Doll[];
  const me = useRouteLoaderData('me') as User;
  return (
    <Card>
      <CardHeader>
        <CardTitle>Dolls</CardTitle>
        <CardDescription>Showing all your Dolls.</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>MacAddress</TableHead>
              <TableHead className='text-right'>Action</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {dolls.map((doll) => (
              <TableRow>
                <TableCell>{doll.name}</TableCell>
                <TableCell>{doll.macAddress}</TableCell>
                <TableCell className='text-right'>
                  <Button size='sm' variant='outline'>
                    <Link to={`/dolls/${doll.id}/edit`}>Edit</Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className='text-xs text-muted-foreground'>New Dolls are addet Automatically when you connect a new Doll.</div>
      </CardFooter>
    </Card>
  );
};

export default Dolls;
