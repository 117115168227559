import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import { Avatar } from '../types';
import axios from 'axios';
import { backendUrl } from '..';
import { useState } from 'react';
import DynamicSelectInput from '../components/DynamicSelectInput';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    return redirect('/signin');
  }

  console.log('params', params);
  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);

  if (!params.avatarId) return;
  try {
    await axios.patch(`${backendUrl}/avatars/${params.avatarId}`, updates, config);
    return redirect(`/avatars/${params.avatarId}`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function AvatarEdit() {
  const avatar = useLoaderData() as Avatar;
  const { id, name, shortDesc, character, published, ttsVoiceId, role } = avatar;
  const [checked, setChecked] = useState(published);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit Avatar</CardTitle>
      </CardHeader>
      <Form method='post' id='avatar-form' encType='multipart/form-data'>
        <CardContent>
          <div className='grid gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='Alice' defaultValue={name} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='shortDesc'>Short Description</Label>
              <Input
                id='shortDesc'
                name='shortDesc'
                type='text'
                className='w-full'
                placeholder='Alice the helpful assistant'
                defaultValue={shortDesc}
                required
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Voice</Label>
              <DynamicSelectInput
                selectID='ttsVoiceId'
                defaultSelectID={ttsVoiceId}
                apiUrl='/tts-voices'
                valueField='id'
                labelField='name'
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Character</Label>
              <Textarea
                required
                defaultValue={character}
                id='character'
                name='character'
                rows={20}
                placeholder='Character description in w++ format'
              />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>Picture</Label>
              <Input id='file-button' name='picture' type='file' />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='ttsVoiceId'>published</Label>
              <Checkbox id='published' name='published' checked={checked} onCheckedChange={() => setChecked(!checked)} />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/avatars/${avatar.id}`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
