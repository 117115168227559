import { ActionFunction, Form, Link, redirect, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Doll, Scenario, User } from '../types';

import { backendUrl } from '..';
import DollDelete from './DollDelete';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Separator } from '@radix-ui/react-dropdown-menu';

export default function DollView() {
  const doll = useLoaderData() as Doll;
  const me = useRouteLoaderData('me') as User;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{doll.macAddress}</CardTitle>
        <CardDescription>{doll.name}</CardDescription>
      </CardHeader>

      <CardContent>
        <div className='whitespace-pre-line'>{doll.macAddress}</div>
        <Separator className='my-4' />
      </CardContent>

      <CardFooter>
        <Button className='gap-1'>
          <Link to={`/dolls`}>Cancel</Link>
        </Button>

        <Button className='gap-1'>
          <Link to={`/dolls/${doll.id}/edit`}>Edit</Link>
        </Button>
      </CardFooter>
    </Card>
  );
}
