import React, { useEffect, useState } from 'react';
import { useFetcher } from 'react-router-dom';

interface DynamicSelectInputProps {
  selectID: string;
  defaultSelectID?: string; // Make defaultSelectID optional
  apiUrl: string;
  valueField?: string;
  labelField?: string;
}

const DynamicSelectInput: React.FC<DynamicSelectInputProps> = ({
  selectID,
  defaultSelectID,
  apiUrl,
  valueField = 'id',
  labelField = 'name',
}) => {
  const fetcher = useFetcher();
  const [loadedData, setLoadedData] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<string | undefined>(defaultSelectID);

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load(apiUrl);
    }
  }, [fetcher, apiUrl]);

  useEffect(() => {
    if (fetcher.data) {
      setLoadedData(fetcher.data);
      if (defaultSelectID) {
        setDefaultValue(defaultSelectID); // Set the default value if provided
      }
    }
  }, [fetcher.data, defaultSelectID]);

  // Function to safely access nested fields
  const getNestedValue = (obj: any, field: string) => {
    return field.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <select
      name={selectID}
      id={selectID}
      value={defaultValue}
      onChange={(e) => setDefaultValue(e.target.value)}
      className='flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1'
    >
      {loadedData.map((item) => (
        <option key={getNestedValue(item, valueField)} value={getNestedValue(item, valueField)}>
          {getNestedValue(item, labelField)}
        </option>
      ))}
    </select>
  );
};

export default DynamicSelectInput;
