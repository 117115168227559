import React, { useEffect, useState } from 'react';
import { ActionFunction, json, redirect, useFetcher, useRouteLoaderData } from 'react-router-dom';
import axios from 'axios';
import mqtt from 'mqtt';
import * as localForage from 'localforage';
import { MicrophoneIcon, PencilIcon } from '@heroicons/react/20/solid';

import { backendUrl } from '..';
import MessageTextNew from './MessageTextNew';
import MessageAudioNew from './MessageAudioNew';
import { Chat, JobEvent, TtsJob, User } from '@/types';

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);

  try {
    // Make the API request to send the message
    await axios.post(`${backendUrl}/messages`, body, config);

    // Redirect back to the messages page after submission
    return redirect(`/chats/${params.chatId}/messages`);
  } catch (error: any) {
    // In case of an error, return a JSON response with error details
    return json({ error: error.response?.data?.message || 'Something went wrong' }, { status: 400 });
  }
};

const MessageNew: React.FC = () => {
  const me = useRouteLoaderData('me') as User;

  const [isAudioMessage, setIsAudioMessage] = useState(true);
  const chat = useRouteLoaderData('chat') as Chat; // Fetch the chat from route loader

  const [ttsJob, setTtsJob] = useState<TtsJob | null>(null);

  let fetcher = useFetcher();
  const [audioURL, setAudioURL] = React.useState<string | null>(null);

  const mqttClientRef = React.useRef<mqtt.MqttClient | null>(null);

  useEffect(() => {
    if (ttsJob) {
      console.log(ttsJob);
      const message = ttsJob.message;
      setAudioURL(`${backendUrl}/messages/${message.id}/audio`);
    }
  }, [ttsJob]);

  useEffect(() => {
    if (fetcher.data) {
      const newTtsJob: TtsJob = fetcher.data;
      if (ttsJob) {
        if (newTtsJob.id !== ttsJob.id) {
          setTtsJob(newTtsJob);
        }
      } else {
        setTtsJob(newTtsJob);
      }
    }
  }, [fetcher.data]);

  // get the tts job id
  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;

      const userTopic = `users/${me.id}`;
      client.subscribe(userTopic);

      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const jobEvent: JobEvent = JSON.parse(message.toString());
          if (jobEvent.jobStatus === 'completed' && jobEvent.resourceName === 'ttsJobs') {
            //setTtsJobId(jobEvent.resourceId);
            fetcher.load(`/tts-jobs/${jobEvent.resourceId}`);
          }
        }
      };

      client.on('message', handleMessage);

      // Clean up the subscription and event listener on unmount
      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end(); // Disconnect the client
        mqttClientRef.current = null;
      };
    }
  }, [me.id, fetcher]);

  useEffect(() => {
    const fetchMessageType = async () => {
      const storedType = await localForage.getItem<boolean>('messageType');
      if (storedType !== null) {
        setIsAudioMessage(storedType);
      }
    };
    fetchMessageType();
  }, []);

  const toggleMessageType = () => {
    setIsAudioMessage((prev) => {
      const newType = !prev;
      localForage.setItem('messageType', newType);
      return newType;
    });
  };

  const handleNewRecording = () => {
    console.log('handleNewRecording');
    setAudioURL(null);
    console.log('audioURL', audioURL);
  };

  return (
    <div className='flex justify-between w-full items-center gap-5'>
      <button onClick={toggleMessageType} className='h-12 border-2 p-2.5'>
        {isAudioMessage ? <PencilIcon className='h-5 w-5' /> : <MicrophoneIcon className='h-5 w-5' />}
      </button>

      <div>
        {isAudioMessage ? <MessageAudioNew onRecordingStart={handleNewRecording} chatId={chat.id} /> : <MessageTextNew chatId={chat.id} />}
        {audioURL && <audio key={audioURL} src={audioURL} autoPlay />}
      </div>
    </div>
  );
};

export default MessageNew;
