import { ActionFunction, Form, Link, redirect, useFetcher, useLoaderData, useParams, json } from 'react-router-dom';
import { Avatar, Chat, ChatModel, EmbeddingModel, Scenario, SttProvider } from '../types';
import axios from 'axios';
import { FunctionComponent, useEffect } from 'react';
import { backendUrl } from '..';
import DynamicSelectInput from '../components/DynamicSelectInput';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ request }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  const formData = await request.formData();
  const body = Object.fromEntries(formData);
  try {
    const response = await axios.post(`${backendUrl}/chats`, body, config);
    const newChat: Chat = response.data;
    return redirect(`/chats/${newChat.id}/messages`);
  } catch (error: any) {
    return json({ error: error.response?.data?.message || 'Something went wrong' }, { status: 400 });
  }
};

export default function ChatNew() {
  const scenario = useLoaderData() as Scenario;
  const avatar: Avatar = scenario.avatar;
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Settings</CardTitle>
        </CardHeader>

        <Form method='post' id='chat-form'>
          <input hidden name='scenarioId' id='scenarioId' value={scenario.id} readOnly />
          <CardContent>
            <div className='grid gap-6'>
              <div className='grid gap-6'>
                <div className='grid gap-3'>
                  <Label htmlFor='sttProviderId'>Speech To Text Provider</Label>
                  <DynamicSelectInput selectID='sttProviderId' apiUrl='/stt-providers' />
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter className='border-t px-6 py-4 gap-2'>
            <Button>
              <Link to={`/avatars`}>Cancel</Link>
            </Button>
            <Button type='submit'>Start Chat</Button>
          </CardFooter>
        </Form>
      </Card>
      <div className='flex gap-5'>
        <Card>
          <CardHeader>
            <CardTitle>{avatar.name}</CardTitle>
            <CardDescription>{avatar.shortDesc}</CardDescription>
          </CardHeader>

          <CardContent>
            <img
              className='object-cover'
              src={
                avatar.picture
                  ? `${backendUrl}/avatars/${avatar.id}/picture.webp?x=300&y=300`
                  : `https://placehold.co/300x300?text=No+Picture`
              }
              alt=''
            />
          </CardContent>

          <CardFooter className='border-t px-6 py-4 gap-2'>
            <Button className='gap-1'>
              <Link to={`/avatars/${avatar.id}`}>View</Link>
            </Button>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>{scenario.name}</CardTitle>
            <CardDescription>{scenario.shortDesc}</CardDescription>
          </CardHeader>

          <CardContent>
            <img
              className='object-cover'
              src={
                scenario.picture
                  ? `${backendUrl}/scenarios/${scenario.id}/picture.webp?x=300&y=300`
                  : `https://placehold.co/300x300?text=No+Picture`
              }
              alt=''
            />
          </CardContent>

          <CardFooter className='border-t px-6 py-4 gap-2'>
            <Button className='gap-1'>
              <Link to={`/scenarios/${scenario.id}`}>View</Link>
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
