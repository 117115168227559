import { JobEvent, User } from '@/types';
import { useEffect, useRef, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import mqtt from 'mqtt';
import EarHearingIcon from 'mdi-react/EarHearingIcon';
import BrainIcon from 'mdi-react/BrainIcon';
import AccountVoiceIcon from 'mdi-react/AccountVoiceIcon';
import VectorPolylinePlusIcon from 'mdi-react/VectorPolylinePlusIcon';
import CurrencyEthIcon from 'mdi-react/CurrencyEthIcon';

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

const sttJob: JobEvent = {
  resourceName: 'sttJobs',
  resourceId: '2493182c-5312-4478-b9c1-590e57527f95',
  jobName: 'created',
  jobId: 60,
  jobStatus: 'active',
};
const embedingJob: JobEvent = {
  resourceName: 'embeddingJobs',
  resourceId: '846a3e06-ca20-45d7-a170-1e8d1084b8f1',
  jobName: 'created',
  jobId: 192,
  jobStatus: 'active',
};
const chatCompletionJob: JobEvent = {
  resourceName: 'chatCompletionJobs',
  resourceId: '2a84b4b0-3365-465a-b575-e69190c58675',
  jobName: 'created',
  jobId: 90,
  jobStatus: 'active',
};
const ttsJob: JobEvent = {
  resourceName: 'ttsJobs',
  resourceId: '704535a7-0800-45ae-b0ce-844d8cf86275',
  jobName: 'created',
  jobId: 106,
  jobStatus: 'active',
};
const paymentJob: JobEvent = {
  resourceName: 'paymentJobs',
  resourceId: '463def6f-010a-4425-8d71-d4cf4fae1d26',
  jobName: 'created',
  jobId: 635,
  jobStatus: 'active',
};

const testJobEvents = [sttJob, embedingJob, chatCompletionJob, ttsJob, paymentJob];

const Toast: React.FC = () => {
  const me = useRouteLoaderData('me') as User;
  const mqttClientRef = useRef<mqtt.MqttClient | null>(null);
  const [jobEvents, setJobEvents] = useState<JobEvent[]>([]);

  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;

      const userTopic = `users/${me.id}`;
      client.subscribe(userTopic);

      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const jobEvent: JobEvent = JSON.parse(message.toString());
          setJobEvents((prevJobEvents) => {
            if (jobEvent.jobStatus === 'active') {
              return [...prevJobEvents, jobEvent];
            } else if (jobEvent.jobStatus === 'completed') {
              return prevJobEvents.filter((event) => event.jobId !== jobEvent.jobId);
            } else {
              return prevJobEvents;
            }
          });
        }
      };

      client.on('message', handleMessage);

      // Clean up the subscription and event listener on unmount
      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end(); // Disconnect the client
        mqttClientRef.current = null;
      };
    }
  }, [me.id]); // Only re-run the effect if the user ID changes

  return (
    <div className='container mx-auto'>
      <ul className='list-none absolute bottom-0'>
        {jobEvents.map((event) => (
          <li key={`${event.jobId}-${event.resourceId}`} className='p-2 border-solid border-2 border-black bg-white bg-opacity-90 mb-2'>
            {event.resourceName === 'sttJobs' && (
              <div className='flex items-center text-sm'>
                <EarHearingIcon size={18} />
                <p className='ml-2'>Speech To Text</p>
              </div>
            )}
            {event.resourceName === 'chatCompletionJobs' && (
              <div className='flex items-center text-sm'>
                <BrainIcon size={18} />
                <p className='ml-2'>Chat Completion</p>
              </div>
            )}
            {event.resourceName === 'embeddingJobs' && (
              <div className='flex items-center text-sm'>
                <VectorPolylinePlusIcon size={18} />
                <p className='ml-2'>Embeddings</p>
              </div>
            )}
            {event.resourceName === 'ttsJobs' && (
              <div className='flex items-center text-sm'>
                <AccountVoiceIcon size={18} />
                <p className='ml-2'>Text To Speech</p>
              </div>
            )}
            {event.resourceName === 'paymentJobs' && (
              <div className='flex items-center text-sm'>
                <CurrencyEthIcon size={18} />
                <p className='ml-2'>ETH Transaction</p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Toast;
