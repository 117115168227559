import React, { useEffect, useRef } from 'react';
import { Link, Outlet, useLoaderData, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import mqtt from 'mqtt';

import MessageItem from './MessageItem';
import { Button } from '@/components/ui/button';
import { Chat, JobEvent, Message, User } from '../types';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

export const mqttHost = process.env.REACT_APP_MQTT_URL || 'mqtt://localhost:1883';

const Messages: React.FC = () => {
  const messages = useLoaderData() as Message[];
  const chat = useRouteLoaderData('chat') as Chat;
  const me = useRouteLoaderData('me') as User;
  let revalidator = useRevalidator();

  const mqttClientRef = useRef<mqtt.MqttClient | null>(null);

  useEffect(() => {
    if (!mqttClientRef.current) {
      const client = mqtt.connect(mqttHost);
      mqttClientRef.current = client;

      const userTopic = `users/${me.id}`;
      client.subscribe(userTopic);

      const handleMessage = (topic: string, message: Buffer) => {
        if (topic === userTopic) {
          const jobEvent: JobEvent = JSON.parse(message.toString());
          if (jobEvent.jobStatus === 'completed') {
            revalidator.revalidate();
          }
        }
      };

      client.on('message', handleMessage);

      // Clean up the subscription and event listener on unmount
      return () => {
        client.unsubscribe(userTopic);
        client.off('message', handleMessage);
        client.end(); // Disconnect the client
        mqttClientRef.current = null;
      };
    }
  }, [me.id]); // Only re-run the effect if the user ID changes

  useEffect(() => {
    const messagesDiv = document.getElementById('messages');
    if (messagesDiv) {
      messagesDiv.scrollTop = messagesDiv.scrollHeight;
      messagesDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  return (
    <Card className='h-[calc(100vh-50px)] sm:h-[calc(100vh-150px)] flex flex-col'>
      <CardHeader className='p-1 sm:p-5 flex flex-row items-start bg-muted/50 gap-3'>
        <div className='grid gap-0.5'>
          <CardTitle className='group flex items-center gap-2 text-lg'>Chat Messages</CardTitle>
          <CardDescription>{`${chat.scenario.name} with ${chat.scenario.avatar.name}`}</CardDescription>
        </div>
        <div className='ml-auto flex items-center gap-1'>
          <Button size='sm' variant='outline'>
            <Link to={`/chats/${chat.id}`}>Settings</Link>
          </Button>
        </div>
      </CardHeader>
      <CardContent className='flex-1 overflow-hidden p-0 sm:px-5'>
        <ScrollArea className='h-full rounded-md border py-2'>
          <div id='messages' className='flex flex-col gap-5 sm:p-4'>
            {messages.map((message) => (
              <MessageItem key={message.id} chat={chat} message={message} />
            ))}
          </div>
        </ScrollArea>
      </CardContent>
      <CardFooter className='flex  py-3 px-5'>
        <Outlet />
      </CardFooter>
    </Card>
  );
};

export default Messages;
