import React, { useEffect } from 'react';
import axios from 'axios';
import { Chat, ChatModel, User } from '../types';
import { ActionFunction, Link, Outlet, redirect, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import ChatDelete from './ChatDelete';
import { backendUrl } from '..';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Progress } from '@/components/ui/progress';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  let config = {
    headers: { Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}` },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.chatId) return;
  await axios.patch(backendUrl + `/chats/${params.chatId}`, updates, config);
  return redirect(`/chats/${params.chatId}`);
};

const ChatView: React.FC = () => {
  const chat = useLoaderData() as Chat;
  const chatCompletionJobs = chat.chatCompletionJobs;
  const scenario = chat.scenario;
  const chatModel = scenario.chatModel;

  const avatar = scenario.avatar;

  const me = useRouteLoaderData('me') as User;

  return (
    <main className='grid flex-1 items-start gap-4 sm:p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3'>
      <div className='grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2'>
        <Card>
          <CardHeader>
            <CardTitle>Chat Completions</CardTitle>
            <CardDescription>completions: {chat._count.chatCompletionJobs}</CardDescription>
          </CardHeader>

          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>usdCost</TableHead>
                  <TableHead>timeTakenMs</TableHead>
                  <TableHead>ContextWindow</TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {chatCompletionJobs.map((chatCompletionJob) => (
                  <TableRow>
                    <TableCell>{chatCompletionJob.usdCost}</TableCell>
                    <TableCell>{chatCompletionJob.timeTakenMs}</TableCell>
                    <TableCell>
                      <Progress value={(chatCompletionJob.inputTokens / chatModel.maxTokens) * 100} aria-label='25% increase' />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>{`${chat.scenario.name} with ${avatar.name}`}</CardTitle>
          <CardDescription>Messages: {chat._count.messages}</CardDescription>
        </CardHeader>

        <CardContent>
          <div className='font-semibold'>Chat Model</div>
          <span className='text-muted-foreground'>{chatModel.name}</span>

          <Separator className='my-4' />

          <div className='font-semibold'>Avatar</div>
          <span className='text-muted-foreground'>{avatar.name}</span>

          <Separator className='my-4' />

          <div className='font-semibold'>Scenario</div>
          <span className='text-muted-foreground'>{scenario.name}</span>
        </CardContent>

        <Separator className='my-4' />

        <CardFooter className='flex justify-between'>
          <Button className='gap-1'>
            <Link to={`/chats/${chat.id}/messages`}>Messages</Link>{' '}
          </Button>
          <ChatDelete />
        </CardFooter>
      </Card>
    </main>
  );
};

export default ChatView;
