import { ActionFunction, Form, Link, redirect, useLoaderData } from 'react-router-dom';
import { Doll } from '../types';
import axios from 'axios';
import { backendUrl } from '..';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import DynamicSelectInput from '@/components/DynamicSelectInput';
import { Button } from '@/components/ui/button';

export const action: ActionFunction = async ({ request, params }) => {
  const localStorageToken = localStorage.getItem('token');

  if (!localStorageToken) {
    return redirect('/signin');
  }

  let config = {
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${localStorageToken?.replaceAll('"', '')}`,
    },
  };

  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  if (!params.dollId) return;
  try {
    await axios.patch(`${backendUrl}/dolls/${params.dollId}`, updates, config);
    return redirect(`/dolls`);
  } catch (error) {
    console.log('error', error);
  }
};

export default function DollEdit() {
  const doll = useLoaderData() as Doll;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit Doll</CardTitle>
      </CardHeader>
      <Form method='post' id='avatar-form' encType='multipart/form-data'>
        <input type='hidden' value={doll.macAddress} name='macAddress' />
        <CardContent>
          <div className='grid gap-6'>
            <div className='grid gap-3'>
              <Label htmlFor='name'>Name</Label>
              <Input id='name' name='name' type='text' className='w-full' placeholder='My Doll' defaultValue={doll.name} required />
            </div>

            <div className='grid gap-3'>
              <Label htmlFor='chatId'>Chat</Label>
              <DynamicSelectInput
                selectID='chatId'
                defaultSelectID={doll.chatId}
                apiUrl='/chats'
                valueField='id'
                labelField='scenario.name'
              />
            </div>
          </div>
        </CardContent>
        <CardFooter className='border-t px-6 py-4 gap-2'>
          <Button>
            <Link to={`/dolls`}>Cancel</Link>
          </Button>
          <Button type='submit'>Save</Button>
        </CardFooter>
      </Form>
    </Card>
  );
}
